import React,{useEffect} from 'react'
import './Header.css'
import headerimg from './images/header.webp'
import AOS from 'aos'
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

function Header() {

    useEffect(()=>{
        AOS.init({duration:2000});
    })

  return (
        <section id="main">
            <div className="main-text" data-aos="fade-up">
                <h1>DECENTRALIZED<br/>AFFILIATE<br/> <span>PROGRAM</span></h1>
                <p><b>NFT-Xpress</b> is an affiliate marketing platform providing our community with early access to some of the most innovative NFT projects. Carefully selected NFT projects are then set within a decentralised autonomous organisation where the smart contracts ensure the system is dependable, secure, and trustless. Early access ensures our investors see the ultimate financial gain.</p>
                <div className="main-btns">
                    <Link className="active-btn-header service-btn" to="/login">LOGIN</Link>
                    <Link to="/signup" className="about-btn">REGISTER</Link>
                </div>
            </div>
            <div className="main-img" data-aos="zoom-out">
                <img alt="nftxpress feature images" src={headerimg} />
            </div>
        </section>
  )
}

export default Header