import React from 'react'
import Navbar from '../Components/Navbar/Navbar'
import Header from '../Components/Header/Header'
import About from '../Components/About/About'
import Product from '../Components/Product/Product'
import Contact from '../Components/Contact/Contact'
import Footer from '../Components/Footer/Footer'
import ProductSlider from '../Components/Product Section Slider/ProductSlider'

function Home() {
  return (
    <> 
        <Navbar/>
        <Header/>
        <About/>
        <ProductSlider/>
        <Product/>
        <Contact/>
        <Footer/>
    </>
  )
}

export default Home