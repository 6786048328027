import './App.css';
import Home from './Pages/Home';
import Signup from './Pages/Signup/Signup';
import Signinp from './Pages/Signin/Signinp';
import { BrowserRouter,Routes ,Route } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop/>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/signup' element={<Signup/>} />
          <Route path='/login' element={<Signinp/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
