import React from 'react'
import './login.css'
import loginimg from './images/login.png'
import { Link } from 'react-router-dom'


function Login() {
  return (
    <section id="login">
            <div className="login-text" >
                <h1>SIGN IN YOUR<br/> <span>ACCOUNT</span></h1>
                <p>To Keep connected with us please login with your personal info.</p>
                <div id='login-form'>
                    <form >
                        <div class="login-form-inputs">
                            <input type="text" name="firstname" placeholder="Login" required/>
                            <input type="text" name="lastname" placeholder="Password" required/>
                        </div>
                        <div class="form-inputs-checkbox">
                            <input className='checkbox' type="checkbox" name="phone" required />
                            <p className='checkbox-p'>Remember Me, <span>Forgot Password?</span></p>
                        </div>
                        <div class="login-btn">
                            <input type="submit" value="Sign In"/>
                        </div>
                        <p className='form-p'>Create An Account <Link className='signup-link' to="/signup">Sign Up</Link></p>
                    </form>
                </div>
            </div>
            <div className="login-img">
                <img alt="nftxpress login image" src={loginimg} />
            </div>
        </section>
  )
}

export default Login