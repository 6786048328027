import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Navbar from '../../Components/Navbar/Navbar'
import Login from '../../Components/Login/Login'

function Signinp() {
  return (
        <>
            <Navbar/>
            <Login/>
            <Footer/>
        </>   
  )
}

export default Signinp