import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Navbar from '../../Components/Navbar/Navbar'
import Signupc from '../../Components/Signup/Signup'
import './signup.css'

function Signup() {
  return (
    <section className='signup-page'>
        <Navbar/>
        <Signupc/>
        <Footer/>
    </section>
    
  )
}

export default Signup